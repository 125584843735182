import { Link, useNavigate, useSearchParams } from '@remix-run/react';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { $path } from 'remix-routes';
import { match } from 'ts-pattern';
import { proxy } from 'valtio';

import {
  type DtoFormResponse,
  type DtoGamePack,
  type DtoGamePackUGCFile,
  type DtoRecommendCourseCandidate,
  type DtoRecommendTrainingCoursesRequest,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';

import { useAuthAnalytics } from '../../analytics/auth';
import { useLearningAnalytics } from '../../analytics/learning';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { hubspotTrackInstantQuoteFormSubmitted } from '../../tracking/hubspot';
import { isGuest } from '../../types/user';
import { fromMediaDTO, toMediaDTO } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { assertExhaustive, err2s } from '../../utils/common';
import { createProvider } from '../../utils/createProvider';
import {
  b64toMasqueradeMedia,
  b64toURL,
  b64URLtoBlob,
} from '../../utils/media';
import { MonotonicallyIncrementingId } from '../../utils/MonotonicallyIncrementingId';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { usePostLogin } from '../Access';
import { EMAIL_PATTERN } from '../Access/types';
import { LegalDisclaimer } from '../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { UGCFileManagerProvider } from '../Game/UGC';
import { useUGCFileManager } from '../Game/UGC/CustomGameFileManager';
import { CustomGamePackPromptEditor } from '../Game/UGC/CustomGamePackPromptEditor';
import { useAbortableBlockGenAI } from '../Game/UGC/utils';
import { GamePackCoverImage } from '../GamePackCover/GamePackCoverPres';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { LunaParkLogo } from '../icons/LogoIcon';
import { Loading } from '../Loading';
import { ProvidersList } from '../ProvidersList';
import { useUser } from '../UserContext';
import { useOpenTrainingEditor } from './Editor/useOpenCloseTrainingEditor';
import { Banner } from './TrainingStarter';
import { openTrainingTypeform } from './TrainingTypeform';
import { pullCourseCreationStatus, useCreateEmptyGamePack } from './utils';

type Props = {
  pack: DtoGamePack | null;
  files: DtoGamePackUGCFile[];
  template: DtoGamePack | null;
  prompt: string | null;
  formResponse: DtoFormResponse | null;
  starterOrigin: string | null;
};

function Layout(props: { children: React.ReactNode }) {
  return (
    <div
      className='w-full h-full flex flex-col items-center'
      style={{
        background: `url('${getStaticAssetPath('images/ugc/background.png')}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {props.children}
    </div>
  );
}

function Header(props: { starterOrigin: string | null }) {
  let redirectLink = $path('/learning/admin');
  if (props.starterOrigin === 'landing') {
    redirectLink = $path('/learning/starter');
  }
  if (props.starterOrigin === 'pest-landing') {
    redirectLink = $path('/learning/pest-control');
  }
  return (
    <header
      className={`
              w-full flex-none h-14 bg-black border-b border-secondary 
              flex items-center justify-center text-white px-4 lg:px-7.5`}
    >
      <div className='flex items-center w-full h-full'>
        <div className='w-0 sm:w-1/3 h-full items-center gap-7.5 text-sms hidden sm:flex'>
          <Link to={redirectLink}>
            <LunaParkLogo className='w-27 h-7.5 mr-4' />
          </Link>
        </div>
        <div className='w-full sm:w-1/3 h-full flex items-center justify-center'>
          <p className='font-bold'>Creator Assistant</p>
        </div>
        <div className='w-0 sm:w-1/3 h-full'></div>
      </div>
    </header>
  );
}

type MessageRole = 'user' | 'assistant';
type MessageState = 'idle' | 'loading' | 'error' | 'success' | 'abort';

type SimpleMessageItem = {
  id: string;
  type: 'simple';
  text: string;
  role: MessageRole;
  state: MessageState;
};
type RecommendTemplatesMessageItem = {
  id: string;
  type: 'recommend-templates';
  role: 'assistant';
  state: MessageState;
  candidates: DtoRecommendCourseCandidate[];
  prompt: string;
  formResponse?: DtoFormResponse | null;
  promptOrigin: string | null;
};

type MessageItem = SimpleMessageItem | RecommendTemplatesMessageItem;

type State = {
  packId: string | null;
  messages: MessageItem[];
};

class Assistant {
  private idgen = new MonotonicallyIncrementingId('asst-msg');
  private _state = markSnapshottable(proxy<State>(this.initialState()));

  init(packId: string) {
    if (this._state.packId === packId) return false;
    this._state.packId = packId;
    return true;
  }

  async recommendCourses(
    req: DtoRecommendTrainingCoursesRequest,
    signal?: AbortSignal,
    promptOrigin: string | null = null
  ) {
    const packId = this.state.packId;
    if (!packId) return;
    this.appendMessage({
      id: this.idgen.next(),
      type: 'simple',
      text: req.prompt,
      role: 'user',
      state: 'success',
    });
    const msg = this.appendMessage({
      id: this.idgen.next(),
      type: 'recommend-templates',
      state: 'loading',
      role: 'assistant',
      candidates: [],
      prompt: req.prompt,
      formResponse: req.formResponse,
      promptOrigin: promptOrigin,
    }) as RecommendTemplatesMessageItem;
    try {
      const resp = await apiService.gamePack.recommendTrainingCourses(
        packId,
        req,
        { signal }
      );
      msg.candidates = resp.data.candidates;
      msg.state = 'success';
      this.updateMessage(msg);
    } catch (error) {
      if (signal?.aborted) {
        msg.state = 'abort';
      } else {
        msg.state = 'error';
      }
      this.updateMessage(msg);
      throw error;
    }
  }

  async generateCourse(
    prompt: string,
    candidate: DtoRecommendCourseCandidate,
    coverMediaId: string | null,
    formResponse?: DtoFormResponse | null
  ) {
    const packId = this.state.packId;
    if (!packId) return;
    const resp = await apiService.gamePack.generateTrainingCourse(packId, {
      prompt,
      templateId: candidate.templateId,
      name: candidate.name,
      description: candidate.description,
      coverMediaId: coverMediaId ?? undefined,
      formResponse: formResponse,
    });
    return resp.data.packId;
  }

  private appendMessage(message: MessageItem) {
    this.state.messages.push(message);
    return message;
  }

  private updateMessage(message: MessageItem) {
    const index = this.state.messages.findIndex((msg) => msg.id === message.id);
    if (index === -1) return;
    ValtioUtils.update(this.state.messages[index], message);
  }

  get state(): ValtioSnapshottable<State> {
    return this._state;
  }

  initialState(): State {
    return {
      packId: null,
      messages: [],
    };
  }
}

const { Provider, useCreatedContext } = createProvider<Assistant>(
  'TrainingCreatorAssistant'
);

function AssistantProvider(props: { children?: React.ReactNode }) {
  const instance = useMemo(() => new Assistant(), []);
  return <Provider value={instance}>{props.children}</Provider>;
}

export function useAssistant(): Assistant {
  return useCreatedContext();
}

export function useMessages() {
  const assistant = useAssistant();
  return useSnapshot(assistant.state).messages as MessageItem[];
}

export function useLastMessageState(): MessageState | undefined {
  const messages = useMessages();
  return messages[messages.length - 1]?.state;
}

function CourseGeneration(props: {
  packId: string;
  candidate: DtoRecommendCourseCandidate;
  isRunning: boolean;
  error: unknown;
  onClose: () => void;
  useMiniView: boolean;
  analytics: ReturnType<typeof useLearningAnalytics>;
  promptOrigin?: string | null;
  showLoading?: boolean;
}) {
  const { isRunning, error } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const backTo = searchParams.get('back-to') || '/learning/admin';

  return (
    <div className='flex flex-col gap-2 md:gap-5 text-white items-center justify-center'>
      <div className='text-xl font-bold'>Creating your experience...</div>
      {(props.showLoading || isRunning) && (
        <Loading
          containerClassName='text-sms font-bold'
          imgClassName='w-5 h-5'
          text='AI is working its magic'
        />
      )}
      {props.useMiniView ? (
        <RecommendCourseCandidateMini candidate={props.candidate} />
      ) : (
        <RecommendCourseCandidate
          candidate={props.candidate}
          analytics={props.analytics}
          promptOrigin={props.promptOrigin ?? null}
        />
      )}
      {isRunning ? (
        <div className='w-77.5 flex flex-col gap-2.5'>
          <button
            type='button'
            className='btn-delete w-full h-10'
            onClick={() => {
              const url = new URL(
                '/learning/assistant',
                window.location.origin
              );
              if (searchParams.has('back-to')) {
                url.searchParams.set('back-to', backTo);
              }
              window.location.href = url.toString();
            }}
          >
            Create another course
          </button>
          <button
            type='button'
            className='btn-secondary w-full h-10'
            onClick={() => navigate(backTo, { replace: true })}
          >
            Go Home
          </button>
          <div className='text-3xs text-center mt-2'>
            You can safely leave this page. We'll continue processing and notify
            you when it's ready.
          </div>
        </div>
      ) : error ? (
        <div className='w-77.5 flex flex-col gap-2.5'>
          <button
            type='button'
            className='w-full h-10 btn-secondary'
            onClick={props.onClose}
          >
            Close
          </button>
          <div className='text-red-002 text-3xs text-center'>
            {err2s(error)}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function AccountCreation(props: {
  onSubmit: (email: string) => Promise<void>;
  isSubmitting: boolean;
  error?: unknown;
}) {
  const { onSubmit, error } = props;

  const { register, handleSubmit, formState, setFocus } = useForm<{
    email: string;
  }>({
    defaultValues: {
      email: '',
    },
  });

  useEffectOnce(() => {
    setFocus('email');
  });

  const analytics = useAuthAnalytics();

  const doSubmit = handleSubmit(async (data) => {
    analytics.trackRegistrationFormSubmitted({
      method: 'email',
      search: window.location.search,
    });

    await onSubmit(data.email);
  });

  return (
    <div className='text-white flex flex-col gap-7.5'>
      <header className='text-xl sm:text-2xl font-medium text-center'>
        One-Step Account Creation
      </header>
      <form onSubmit={doSubmit}>
        <label className='w-full'>
          <p className='font-bold'>What is your work email?</p>
          <input
            className={`mt-2.5 w-full h-12.5 ${
              formState.errors.email ? 'field-error' : 'field'
            } mb-0`}
            placeholder='name@company.com'
            {...register('email', {
              required: true,
              pattern: EMAIL_PATTERN,
              maxLength: 60,
            })}
            maxLength={60}
          />
          <div className='mt-0.5 ml-1 h-3 w-full text-3xs text-red-002 text-left truncate'>
            {formState.errors.email && 'Please enter a valid email address'}
          </div>
        </label>

        <div className='ml-1 -mt-2 h-3 w-full text-3xs text-red-002 text-left truncate'>
          {err2s(error)}
        </div>

        <button
          type='submit'
          className={`mt-0.5 w-full btn-primary h-12.5 flex items-center justify-center`}
          disabled={props.isSubmitting}
        >
          {props.isSubmitting && <Loading text='' containerClassName='mr-2' />}
          Preview My Game
        </button>

        <div className='mt-5 w-full text-center text-sm text-icon-gray'>
          No Credit Card Required
        </div>
      </form>
      <div className='w-full'>
        <div className='w-full text-center text-sms font-medium text-icon-gray hidden'>
          Have an account?{' '}
          <Link
            to={{
              pathname: '/login',
              search: window.location.search,
            }}
            className='text-primary'
          >
            Sign in
          </Link>
        </div>

        <div className='mt-5'>
          <LegalDisclaimer downplay />
        </div>
      </div>
    </div>
  );
}

function CourseGenerationModal(props: {
  prompt: string;
  formResponse?: DtoFormResponse | null;
  candidate: DtoRecommendCourseCandidate;
  assistant: Assistant;
  onClose: () => void;
  userIsGuest: boolean;
}) {
  const { prompt, formResponse, assistant, userIsGuest } = props;
  const packId = assistant.state.packId;

  const [candidate, setCandidate] = useState(props.candidate);
  const [showAccountCreation, setShowAccountCreation] = useState(userIsGuest);
  const analytics = useLearningAnalytics();
  const abortControllerRef = useRef<AbortController>();
  const openTrainingEditor = useOpenTrainingEditor();

  const cleanup = useLiveCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = undefined;
    }
  });

  const {
    call: generate,
    state: { state: generateState, error: generateError },
  } = useLiveAsyncCall(async () => {
    cleanup();
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    let coverMediaId: string | null = null;
    if (candidate.cover?.url.startsWith('data:')) {
      const resp = await apiService.media.upload(
        b64URLtoBlob(candidate.cover.url),
        {
          contentType: 'image/png',
          scene: EnumsMediaScene.MediaSceneGamePackCover,
        }
      );
      coverMediaId = resp.data.media.id;
    }
    const id = await assistant.generateCourse(
      prompt,
      candidate,
      coverMediaId,
      formResponse
    );
    if (!id) return;

    await pullCourseCreationStatus(
      id,
      (pack) => {
        setCandidate((prev) => {
          if (!pack.cover || pack.cover.id === candidate.cover?.id) return prev;
          return {
            ...prev,
            cover: pack.cover,
          };
        });
      },
      signal
    );

    if (!signal.aborted) {
      openTrainingEditor(id, {
        query: { 'preview-tooltip': 'true' },
        replace: true,
      });
    }
  });

  useEffect(() => cleanup, [cleanup]);

  const postLogin = usePostLogin();
  const {
    call: registerWithGenerate,
    state: { state: registerState, error: registerError },
  } = useLiveAsyncCall(async (email: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set('register-for', 'training');
    const queries = Object.fromEntries(params.entries());

    hubspotTrackInstantQuoteFormSubmitted({
      email,
      kind: 'training',
    });

    const resp = await apiService.auth.register({
      email,
      queries,
      activate: false,
      useEmailAsOrgName: true,
      fromGuest: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    postLogin(resp.data);
    setShowAccountCreation(false);
    // Event snippet for AI Training Tool Signup conversion page
    gtag('event', 'conversion', {
      send_to: 'AW-10811798990/pRHPCLOKtocaEM7ru6Mo',
    });
    await generate();
  });

  useEffect(() => {
    if (userIsGuest) return;
    generate();
  }, [generate, userIsGuest]);

  if (!packId) return null;

  const isRunning = generateState.isRunning;

  return (
    <div
      className='flex flex-col-reverse md:flex-row items-center justify-center gap-7.5 md:gap-10
    bg-modal rounded-xl border border-secondary p-10'
    >
      {showAccountCreation ? (
        <AccountCreation
          onSubmit={registerWithGenerate}
          error={registerError}
          isSubmitting={registerState.isRunning}
        />
      ) : null}
      <CourseGeneration
        packId={packId}
        candidate={candidate}
        isRunning={isRunning}
        showLoading={showAccountCreation ? true : isRunning}
        error={generateError}
        onClose={props.onClose}
        useMiniView={!generateState.isDone && showAccountCreation}
        analytics={analytics}
      />
    </div>
  );
}

function useOpenGenerationModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const assistant = useAssistant();
  const user = useUser();

  return useLiveCallback(
    (
      prompt: string,
      candidate: DtoRecommendCourseCandidate,
      formResponse?: DtoFormResponse | null
    ) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <CourseGenerationModal
            prompt={prompt}
            formResponse={formResponse}
            candidate={candidate}
            assistant={assistant}
            onClose={p.internalOnCancel}
            userIsGuest={isGuest(user)}
          />
        ),
      });
    }
  );
}

function SimpleMessage(props: { message: SimpleMessageItem }) {
  const { message } = props;
  return (
    <div
      className={`w-fit-content bg-dark-gray rounded-xl p-3 text-sms ${
        message.role === 'assistant' ? 'self-start' : 'self-end'
      }`}
    >
      {message.text}
    </div>
  );
}

function RecommendCourseCandidate(props: {
  candidate: DtoRecommendCourseCandidate;
  onClick?: (b64Image: string | null) => void;
  autoGenerateCover?: boolean;
  analytics: ReturnType<typeof useLearningAnalytics>;
  promptOrigin: string | null;
}) {
  const { candidate, autoGenerateCover } = props;
  const cover = fromMediaDTO(candidate.cover);
  const [b64Image, setB64Image] = useState<string | null>(null);

  const {
    call: generateCover,
    state: { state },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.gamePack.recommendCoverImages({
      name: candidate.name,
      description: candidate.description,
    });
    if (resp.data.images.length === 0) return;
    setB64Image(resp.data.images[0].b64);
  });

  useEffectOnce(() => {
    if (!candidate.active || !autoGenerateCover) return;
    generateCover();
  });

  const handleClick = () => {
    if (!props.onClick) return;
    props.analytics.trackAssistantTemplateSelected({
      templateName: candidate.name,
      templateId: candidate.templateId,
      promptOrigin: props.promptOrigin,
    });
    props.onClick(b64Image);
  };

  return (
    <div
      className={`w-47.5 h-72.5 bg-white bg-opacity-15 rounded-xl 
        flex flex-col p-2.5 gap-2.5 flex-shrink-0 ${
          candidate.active ? '' : 'opacity-40'
        }`}
    >
      {state.isRunning ? (
        <div
          className='w-full flex-none rounded-xl flex items-center justify-center 
          gap-1 bg-light-gray'
          style={{
            aspectRatio: '16/9',
          }}
        >
          <Loading
            text='Generating Cover'
            imgClassName='w-3.5 h-3.5'
            containerClassName='text-sms'
          />
        </div>
      ) : b64Image ? (
        <img
          src={b64toURL(b64Image)}
          alt='cover'
          className={`w-full flex-none rounded-xl`}
        />
      ) : (
        <GamePackCoverImage
          pack={{ cover }}
          alt='cover'
          className='w-full flex-none rounded-xl'
        />
      )}
      <div className='text-sms flex-none font-bold'>{candidate.name}</div>
      <div className='text-2xs flex-1 overflow-auto scrollbar'>
        {candidate.description}
      </div>
      {props.onClick ? (
        <button
          type='button'
          className='btn-success flex-none mt-auto w-full h-10 rounded-xl flex items-center justify-center gap-2'
          disabled={!candidate.active || state.isRunning}
          onClick={handleClick}
        >
          {candidate.active && (
            <FilledCheckIcon className='w-3.5 h-3.5 fill-current' />
          )}
          {candidate.active ? 'Create Experience' : 'Coming Soon'}
        </button>
      ) : null}
    </div>
  );
}

function RecommendCourseCandidateMini(props: {
  candidate: DtoRecommendCourseCandidate;
}) {
  const { candidate } = props;
  const cover = fromMediaDTO(candidate.cover);
  return (
    <div
      className={`w-47.5 bg-transparent bg-opacity-15 rounded-xl 
        flex flex-col p-2.5 gap-2.5 flex-shrink-0`}
    >
      <GamePackCoverImage
        pack={{ cover }}
        alt='cover'
        className='w-full flex-none rounded-xl'
      />
      <div className='text-sms flex-none font-bold text-center'>
        {candidate.name}
      </div>
    </div>
  );
}

function RecommendTemplatesMessage(props: {
  message: RecommendTemplatesMessageItem;
}) {
  const analytics = useLearningAnalytics();
  const { message } = props;
  const openModal = useOpenGenerationModal();

  return (
    <div className='w-full text-sms flex flex-col gap-5'>
      {match(message.state)
        .when(
          (val) => val === 'idle' || val === 'loading',
          () => (
            <Loading
              imgClassName='w-5 h-5'
              text='Looking for best matches...'
              containerClassName='!justify-start'
            />
          )
        )
        .with('error', () => (
          <div className='text-red-002'>Something is wrong</div>
        ))
        .with('abort', () => (
          <div className='text-red-002'>Request canceled</div>
        ))
        .with('success', () => (
          <div>
            {message.candidates.length > 0
              ? 'Here are some options for you based on your suggestion. Choose the one you want to create and I’ll get that going for you!'
              : 'No options available.'}
          </div>
        ))
        .exhaustive()}
      {message.candidates.length > 0 && (
        <div className='flex gap-2.5 overflow-x-auto scrollbar'>
          {message.candidates.map((candidate) => (
            <RecommendCourseCandidate
              key={candidate.templateId}
              candidate={candidate}
              analytics={analytics}
              promptOrigin={message.promptOrigin}
              onClick={(b64Image) => {
                openModal(
                  message.prompt,
                  {
                    ...candidate,
                    cover: b64Image
                      ? toMediaDTO(b64toMasqueradeMedia(b64Image))
                      : candidate.cover,
                  },
                  message.formResponse
                );
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function MessageList() {
  const messages = useMessages();
  const ref = useRef<HTMLDivElement>(null);

  const messagesCount = messages.length;

  const scrollToBottom = useLiveCallback(() => {
    if (!ref.current) return;
    const scrollHeight = ref.current.scrollHeight;
    const height = ref.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ref.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  });

  useLayoutEffect(() => scrollToBottom(), [scrollToBottom]);
  useLayoutEffect(() => scrollToBottom(), [messagesCount, scrollToBottom]);

  return (
    <div
      ref={ref}
      className='w-full flex-1 sm:w-160 overflow-y-auto scrollbar my-4'
    >
      <div className='w-full min-h-full flex-1 flex flex-col gap-2 justify-end text-white px-2.5'>
        {messages.map((msg) => {
          const msgType = msg.type;
          switch (msgType) {
            case 'simple':
              return <SimpleMessage key={msg.id} message={msg} />;
            case 'recommend-templates':
              return <RecommendTemplatesMessage key={msg.id} message={msg} />;
            default:
              assertExhaustive(msgType);
              return null;
          }
        })}
      </div>
    </div>
  );
}

function PromptEditor(
  props: Props & {
    analytics: ReturnType<typeof useLearningAnalytics>;
    setShowZeroState: (show: boolean) => void;
  }
) {
  const {
    files,
    template,
    prompt: initialPrompt,
    analytics,
    formResponse: initialFormResponse,
    starterOrigin,
  } = props;
  const fileman = useUGCFileManager();
  const assistant = useAssistant();
  const createGamePack = useCreateEmptyGamePack(props.pack);
  const formResponse = useRef(initialFormResponse);
  const hasSeenForm = useRef(!!initialFormResponse);

  useLayoutEffect(() => {
    if (!props.pack?.id) return;
    assistant.init(props.pack.id);
    fileman.init(props.pack.id, files);
  }, [fileman, props.pack?.id, files, assistant]);

  const initFileman = useLiveCallback(async () => {
    if (fileman.inited) return;
    const pack = await createGamePack();
    if (!pack) return;
    if (pack.id !== props.pack?.id) {
    }
    fileman.init(pack.id, []);
  });

  useEffect(() => {
    fileman.uppy.addPreProcessor(initFileman);
    return () => fileman.uppy.removePreProcessor(initFileman);
  }, [fileman.uppy, initFileman]);

  const ensureEverythingInited = useLiveCallback(async () => {
    const pack = await createGamePack();
    if (!pack) return { pack: null, inited: false };
    if (!fileman.inited) {
      fileman.init(pack.id, []);
    }
    if (assistant.init(pack.id)) {
    }
    return { pack, inited: true };
  });

  const { generate, abort, state, error } = useAbortableBlockGenAI(
    async (prompt: string, signal: AbortSignal) => {
      const { pack, inited } = await ensureEverythingInited();
      if (!inited) return false;
      props.setShowZeroState(false);

      analytics.trackCourseAIPrompt({
        packId: pack?.id,
        prompt: prompt,
        promptOrigin: starterOrigin,
      });

      try {
        await assistant.recommendCourses(
          {
            prompt,
            templateId: template?.id,
            formResponse: formResponse.current,
          },
          signal,
          starterOrigin
        );
        return true;
      } catch (error) {
        throw error;
      }
    }
  );

  useEffect(() => {
    if (template?.id || !initialPrompt) return;
    generate(initialPrompt);
  }, [generate, initialPrompt, template?.id]);

  const handleSubmit = useLiveCallback(async (prompt: string) => {
    if (!hasSeenForm.current) {
      hasSeenForm.current = true;
      formResponse.current = await openTrainingTypeform();
    }

    return generate(prompt);
  });

  return (
    <CustomGamePackPromptEditor
      enabled
      onSubmit={handleSubmit}
      onAbort={abort}
      isSubmitting={state.isRunning}
      active
      autoFocus
      error={error}
      disableDeactivate
      placeholder='What training course are you building today?'
      wrapperClassName='mb-12'
      width='w-full sm:w-160 px-2.5'
    />
  );
}

export function TrainingCreatorAssistant(props: Props) {
  const providers = [<UGCFileManagerProvider />, <AssistantProvider />];
  const analytics = useLearningAnalytics();

  const [showZeroState, setShowZeroState] = useState(!props.prompt);

  return (
    <Layout>
      <Header starterOrigin={props.starterOrigin} />
      <div className='w-full h-[calc(100%-56px)] flex flex-col items-center justify-center'>
        <ProvidersList providers={providers}>
          {!showZeroState && <MessageList />}
          <div className='w-full flex flex-col gap-6'>
            {showZeroState && (
              <Banner
                dimWidth={0}
                tagline='instantly-create'
                widthClasses='sm:w-160 w-full px-2'
                videoCtrl='disabled'
              />
            )}
            <PromptEditor
              {...props}
              analytics={analytics}
              setShowZeroState={setShowZeroState}
            />
          </div>
        </ProvidersList>
      </div>
    </Layout>
  );
}
