import { Component } from '../../src/routes/learning.assistant.client';
export default Component ?? (() => <></>);
export { clientLoader } from '../../src/routes/learning.assistant.client';
export { clientAction } from '../../src/routes/learning.assistant.client';
export { meta } from '../../src/routes/learning.assistant.client';
export const HydrateFallback = () => <></>;

export async function action() {
  return null;
}
