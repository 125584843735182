import {
  type ClientActionFunctionArgs,
  type ClientLoaderFunctionArgs,
  type MetaFunction,
  useActionData,
  useLoaderData,
} from '@remix-run/react';

import {
  type DtoFormResponse,
  type DtoGamePack,
  type DtoGamePackUGCFile,
} from '@lp-lib/api-service-client/public';

import { TrainingCreatorAssistant } from '../components/Training/TrainingCreatorAssistant';
import { TrainingOutlineAgent } from '../components/Training/TrainingOutlineAgent';
import {
  type FeatureQueryParamArrays,
  getFeatureQueryParamArrayWith,
} from '../hooks/useFeatureQueryParam';
import { apiService } from '../services/api-service';
import { LearningStarterLayout } from './learning.starter.client';

function getAsString(params: FormData | URLSearchParams, key: string) {
  if (params instanceof FormData) {
    const val = params.get(key);
    if (val instanceof File) {
      throw new Error(`File is not supported, ${key}`);
    }
    return val;
  }
  return params.get(key);
}

type LoadedData = {
  pack: DtoGamePack | null;
  files: DtoGamePackUGCFile[];
  template: DtoGamePack | null;
  prompt: string | null;
  formResponse: DtoFormResponse | null;
  starterOrigin: string | null;
  agentic: FeatureQueryParamArrays['agentic'][number];
  customTemplate: string | null;
  songId: string | null;
};

async function init(
  params: FormData | URLSearchParams,
  search: string
): Promise<LoadedData> {
  const id = getAsString(params, 'id');
  const templateId = getAsString(params, 'template-id');
  const starterOrigin = getAsString(params, 'starter-origin');
  const prompt = getAsString(params, 'prompt');
  const formId = getAsString(params, 'form-id');
  const responseId = getAsString(params, 'response-id');
  const customTemplate = getAsString(params, 'custom-template');
  const songId = getAsString(params, 'song-id');

  const agentic = getFeatureQueryParamArrayWith('agentic', search);

  const formResponse =
    formId && responseId
      ? {
          formId,
          responseId,
        }
      : null;

  const template = templateId
    ? (await apiService.gamePack.getGamePackById(templateId)).data.gamePack
    : null;

  const [pack, files] = id
    ? await Promise.all([
        (await apiService.gamePack.getGamePackById(id)).data.gamePack,
        (await apiService.gamePack.getUGCFiles(id)).data.files,
      ])
    : [null, []];

  return {
    pack,
    files,
    template,
    prompt,
    formResponse,
    starterOrigin,
    agentic,
    customTemplate,
    songId,
  };
}

export async function clientAction({ request }: ClientActionFunctionArgs) {
  const url = new URL(request.url);
  const formData = await request.formData();
  return init(formData, url.search);
}

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  return init(url.searchParams, url.search);
}

export const meta: MetaFunction = () => {
  return [{ title: 'Training Creator Assistant | Luna Park' }];
};

export function Component() {
  const actionData = useActionData<typeof clientAction>();
  const loaderData = useLoaderData<typeof clientLoader>();
  const data = actionData ?? loaderData;
  const {
    pack,
    files,
    template,
    prompt,
    formResponse,
    starterOrigin,
    agentic,
    customTemplate,
    songId,
  } = data;

  return (
    <LearningStarterLayout>
      {agentic !== 'disabled' ? (
        <TrainingOutlineAgent
          pack={pack}
          files={files}
          prompt={prompt}
          starterOrigin={starterOrigin}
          customTemplate={customTemplate}
          songId={songId}
        />
      ) : (
        <TrainingCreatorAssistant
          pack={pack}
          files={files}
          template={template}
          prompt={prompt}
          formResponse={formResponse}
          starterOrigin={starterOrigin}
        />
      )}
    </LearningStarterLayout>
  );
}
